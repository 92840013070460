<template>
    <BT-Dialog
        :getOnOpenAsync="openOverview"
        hideButton
        label="Stock Status Overview"
        :openToggle="openToggle"
        width="auto">
        <template v-slot="{ item }">
            <v-container>
                <v-card v-for="entry in item.entries" :key="entry.date">
                    <v-row class="ma-0 pa-0 py-2">
                        <v-divider class="my-3 mr-3" />
                        <span class="text-overline">{{ entry.date || 'No Due Date' | toDayShortDate }}</span>
                        <v-divider class="my-3 ml-3" />
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-card v-for="lEntry in entry.locations" :key="lEntry.locationID" width="400" class="ma-3">
                            <v-card-title>
                                <BT-Entity
                                    navigation="locations"
                                    :itemValue="lEntry.locationID"
                                    itemText="locationName" />
                            </v-card-title>
                            <v-card-text>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4"></v-col>
                                    <v-col cols="2" class="text-right"></v-col>
                                    <v-col cols="3" class="text-right">Gross</v-col>
                                    <v-col cols="3" class="text-right">Net</v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4" class="text-left">On Order:</v-col>
                                    <v-col cols="2" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].onOrder : 0 | toDisplayNumber }}</v-col>
                                    <v-col cols="3" class="text-right">({{ lEntry.products.length > 0 ? lEntry.products[0].onOrder : 0 | toDisplayNumber }})</v-col>
                                    <v-col cols="3" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].onOrder : 0 | toDisplayNumber }}</v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4" class="text-left">Transfer In:</v-col>
                                    <v-col cols="2" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].transferIn : 0 | toDisplayNumber }}</v-col>
                                    <v-col cols="3" class="text-right">({{ lEntry.products.length > 0 ? lEntry.products[0].transferIn : 0 | toDisplayNumber }})</v-col>
                                    <v-col cols="3" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].transferIn : 0 | toDisplayNumber }}</v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4" class="text-left">Transfer Out:</v-col>
                                    <v-col cols="2" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].transferOut : 0 | toDisplayNumber }}</v-col>
                                    <v-col cols="3" class="text-right">({{ lEntry.products.length > 0 ? lEntry.products[0].transferOut : 0 | toDisplayNumber }})</v-col>
                                    <v-col cols="3" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].transferOut : 0 | toDisplayNumber }}</v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4" class="text-left">Manufacture:</v-col>
                                    <v-col cols="2" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].toMake : 0 | toDisplayNumber }}</v-col>
                                    <v-col cols="3" class="text-right">({{ lEntry.products.length > 0 ? lEntry.products[0].toMake : 0 | toDisplayNumber }})</v-col>
                                    <v-col cols="3" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].toMake : 0 | toDisplayNumber }}</v-col>
                                </v-row>
                                <v-divider class="my-2" />
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4" class="text-left">Total:</v-col>
                                    <v-col cols="2" class="text-right"></v-col>
                                    <v-col cols="3" class="text-right">({{ lEntry.products.length > 0 ? lEntry.products[0].toMake : 0 | toDisplayNumber }})</v-col>
                                    <v-col cols="3" class="text-right">{{ lEntry.products.length > 0 ? lEntry.products[0].toMake : 0 | toDisplayNumber }}</v-col>
                                </v-row>
<!--                                 
                                <v-row class="ma-0 pa-0">
                                    <span>Picked:</span>
                                    <v-spacer />
                                    <span>{{ lEntry.products.length > 0 ? lEntry.products[0].assigned : 0 | toDisplayNumber }}</span>
                                </v-row> -->
                            </v-card-text>
                        </v-card>
                    </v-row>
                </v-card>
            </v-container>
        </template>
    </BT-Dialog>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'BT-Stock-Status-Overview',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    props: {
        openToggle: {
            type: Boolean,
            default: false
        },
        productID: {
            type: String,
            default: null
        }
    },
    methods: {
        async openOverview() {
            var r = {
                spares: [],
                days: [], //dates
                locations: [], //ids
                entries: [] //{ date, locations: [{ productID, ... }] }
            };

            var res = await this.$BlitzIt.store.getAll('stock-items', {}, false, null, '/get/Status');

            res = res.filter(z => z.productID == this.productID);

            res.forEach(v => {
                if (v.spare != 0) {
                    var existingSpare = r.spares.find(z => z.locationID == v.locationID && z.productID == v.productID);
                    if (existingSpare == null) {
                        existingSpare = {
                            productID: v.productID,
                            locationID: v.locationID,
                            quantity: 0
                        };
                        r.spares.push(existingSpare);
                    }
                    existingSpare.quantity += v.spare;
                }

                var day = r.entries.find(z => z.date == v.date);
                if (day == null) {
                    day = {
                        date: v.date,
                        locations: []
                    };
                    r.entries.push(day);
                    r.days.push(v.date);
                }

                var location = day.locations.find(z => z.locationID == v.locationID);
                if (location == null) {
                    location = {
                        locationID: v.locationID,
                        products: []
                    };
                    day.locations.push(location);

                    r.locations.push(v.locationID);
                }

                location.products.push(v);
            })

            r.entries.sort(firstBy(x => x.date == null ? 0 : this.$BlitzIt.auth.formUTCDateTime(x.date).toSeconds()));

            return r;
        },
    }
}
</script>